<template>
	<div id="webSite" class="el-content">
		<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
			<a-tabs v-model:activeKey="form.pay_type">
				<a-tab-pane key="1" tab="支付宝">
					<a-form-item label="身份标识(appId)"><a-input v-model:value="form.alipay_appid"></a-input></a-form-item>
					<a-form-item label="支付宝公钥"><a-textarea v-model:value="form.alipay_public_key" :rows="12" /></a-form-item>
					<a-form-item label="商户私钥"><a-textarea v-model:value="form.alipay_merchant_private_key" :rows="12" /></a-form-item>
				</a-tab-pane>
				<a-tab-pane key="2" tab="微信" force-render>
					<a-form-item label="身份标识(appId)"><a-input v-model:value="form.wx_appid"></a-input></a-form-item>
					<a-form-item label="身份密钥(appSecret)"><a-input v-model:value="form.wx_appsecert"></a-input></a-form-item>
					<a-form-item label="微信支付商户号"><a-input v-model:value="form.wx_mchid"></a-input></a-form-item>
					<a-form-item label="微信支付密钥"><a-input v-model:value="form.wx_paysecert"></a-input></a-form-item>
					<a-form-item label="微信支付证书CERT">
						<upload-cert :value="form.wx_cert" @change="(e)=>{form.wx_cert =e}"></upload-cert>
					</a-form-item>
					<a-form-item label="微信支付证书KEY">
						<upload-cert :value="form.wx_key" @change="(e)=>{form.wx_key =e}"></upload-cert>
					</a-form-item>
				</a-tab-pane>
			</a-tabs>
			<a-form-item :wrapper-col="{ offset: 2 }">
				<a-button type="primary" @click="submitSave">保存</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import saasSetModel from '@/api/saas/set';
import tool from '@/util/tool';
export default {
	setup(){
		const state = reactive({
			form: {
				pay_type: '1',
				wx_key: '',
				wx_cert: '',
				wx_appid: '',
				wx_mchid: '',
				wx_paysecert: '',
				wx_appsecert: '',
				alipay_appid: '',
				alipay_public_key: '',
				alipay_merchant_private_key: '',
				scene: 'pay'
			},
		})

		saasSetModel.getWebConfigSite(['pay_json'],res=>{
			console.log('res',res.pay_json);
			
			state.form = tool.filterForm(Object.keys(state.form),res.pay_json)
		})

		const submitSave =()=>{
			let form = JSON.parse(JSON.stringify(state.form))
			form.scene = 'pay'
			saasSetModel.saveWebConfigSite(form)
		}

		return{
			...toRefs(state),
			submitSave
		}
	}
};
</script>

<style></style>
